
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StandardFlag from "@/components/scope/standard-flag.vue";
import t from "@common/src/i18n/t";

const partsCategory = namespace("parts-category");

@Component({
  components: { StandardFlag }
})
export default class PartsCategory extends Vue {
  @partsCategory.Action createCategory;
  @partsCategory.Action getCategoryList;
  @partsCategory.Action deleteCategory;
  @partsCategory.Action updateCategory;
  partsCategoryList: any[] = [];
  partsCategoryListCopy: any[] = [];
  tableLoading: boolean = false;
  showDeleteTips: boolean = false;
  showNotDeleteTips: boolean = false;
  willDeleteRow: any = {};
  parentRow: any = {};
  created() {
    this.loadListData();
  }
  categoryFormData: any = {
    categoryName: ""
  };
  categoryFormRules: any = {
    categoryName: [{ required: true, message: t("setting.class-name-cannot-empty"), trigger: "blur" }]
  };
  tipsTitle: string = t("setting.new-level-classification");
  manualKey: number = 0;
  keyword: string = "";
  showInputCategoryName: boolean = false;
  sortMethod(a, b) {
    console.log(a, b);
    return a.partsCount - b.partsCount;
  }
  async loadListData(callback?) {
    this.tableLoading = true;
    const data = await this.getCategoryList();
    this.partsCategoryList = this.addlevelFlag(data.data);
    this.partsCategoryListCopy = this.addlevelFlag(data.data);
    this.tableLoading = false;
    callback && callback();
  }
  addlevelFlag(list) {
    const f = function(list, level) {
      list.forEach(item => {
        item.level = level;
        // item.source = "1";
        // item.isCould = true;
        if (item.children && item.children.length > 0) {
          f(item.children, level + 1);
        }
      });
    };
    f(list, 0);
    return list;
  }
  addNode() {
    if (!Object.keys(this.parentRow).length) {
      // 添加根节点
      // this.partsCategoryList.push(newNode);
      this.addCategory(this.categoryFormData.categoryName);
    } else {
      this.addCategory(this.categoryFormData.categoryName, this.parentRow.categoryCode);
    }
    this.showInputCategoryName = false;
    this.categoryFormData.categoryName = "";
  }
  editRow(row) {
    if (row.source === "1") {
      return;
    }
    if (typeof row.input !== "boolean") {
      this.$set(row, "input", true);
    } else {
      row.input = true;
    }
    this.$nextTick(() => {
      console.log(this.$refs["input" + row.categoryCode]);
      (this.$refs["input" + row.categoryCode] as any).focus();
    });
  }

  saveRow(row) {
    this.updateCategory({
      categoryCode: row.categoryCode,
      categoryName: row.categoryName
    }).then(data => {
      row.input = false;
      this.$message.success(this.$t("base.update-success") as string);
    });
  }
  handleSearch() {
    if (this.keyword) {
      this.partsCategoryList = this.partsCategoryList.filter(item => {
        return item.categoryName.includes(this.keyword);
      });
    } else {
      this.partsCategoryList = this.partsCategoryListCopy;
    }
  }
  async handleDelete() {
    await this.deleteCategory({
      categoryCode: this.willDeleteRow.categoryCode
    });
    this.$message.success(this.$t("base.delete-success") as string);
    this.showDeleteTips = false;
    this.loadListData();
  }
  submitForm(formName) {
    (this.$refs[formName] as any).validate(valid => {
      if (valid) {
        this.addNode();
      } else {
        console.log("error submit!!");
      }
    });
  }

  handleAddCategory(row?) {
    if (row.level >= 2) {
      return;
    }
    if (row) {
      this.parentRow = row;
      this.tipsTitle = t("setting.add-subcategories");
    } else {
      this.parentRow = {};
      this.tipsTitle = t("setting.new-level-classification");
    }
    this.showInputCategoryName = true;
  }
  addCategory(name, parentCode = null) {
    this.createCategory({
      categoryName: this.categoryFormData.categoryName,
      parentCode: parentCode
    }).then(data => {
      this.$message.success(t("setting.successfully-added"));
      this.loadListData();
      if (parentCode) {
        this.loadListData(() => {
          this.$nextTick(() => {
            (this.$refs["table"] as any).toggleRowExpansion(this.parentRow, true);
          });
        });
      } else {
        this.loadListData();
      }
    });
  }
  handleDeleteCategory(row) {
    if (row.partsCount > 0) {
      this.showNotDeleteTips = true;
    } else {
      this.willDeleteRow = row;
      this.showDeleteTips = true;
    }
  }
}
